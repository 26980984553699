'use client';
import FocusTrap from 'focus-trap-react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';
import Card from '@ui/atoms/card/Card';

function Modal({ modalContainerClassName, modalClassName, setModalOpen, children }: any) {
  const [isShown, setIsShown] = useState(false);
  const closeButton = useRef(null);
  const modal = useRef(null);

  useEffect(() => {
    setIsShown({ isShown: true }, () => {
      closeButton.current.focus();
    });
    toggleScrollLock();
  }, [children]);

  const closeModal = () => {
    setIsShown(false);
    closeButton.current.focus();
    toggleScrollLock();
    setModalOpen(false);
  };
  const onKeyDown = (event: any) => {
    if (event.keyCode === 27) {
      closeModal();
      setModalOpen(false);
    }
  };
  const onClickOutside = (event: any) => {
    if (modal && modal.current.contains(event.target)) return;
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html')?.classList?.toggle('scroll-lock');
  };
  return ReactDOM.createPortal(
    <FocusTrap>
      {
        isShown ? (
          <Card>
          <div
            onClick={onClickOutside}
            onKeyDown={onKeyDown}
            id="myModal"
            className={`${styles.modal} ${styles.open} ${modalContainerClassName}`}
          >
            <div ref={modal} className={`${styles.modalContent} ${modalClassName}`}>
              <span ref={closeButton} onClick={closeModal} className={styles.close}>
                &times;
              </span>
              <div className={`${styles.cardBody} ${modalClassName}`}>{children}</div>
            </div>
          </div>
          </Card>
        ) : <Card><div><button>Loading...</button></div></Card>
      }
    </FocusTrap>,
    document.body
  );
}

export default Modal;
