'use client';
import { createContext } from 'react';

// type SearchContextType = {
// };

export const SearchContext = createContext<any | undefined>(
  // export const SearchContext = createContext<SearchContextType | undefined>(
  undefined
);
