import originalAxios from 'axios';
import { axiosConfig } from './axiosConfig';
import { getSession, signOut } from 'next-auth/react';
const axios = originalAxios.create(axiosConfig);


const refresh = async () => {
  const response = await axios({
    method: 'get',
    url: '/auth/refresh',
    withCredentials: true,
  });

  // setAuth((prev: any) => {
  //   return { ...prev, accessToken: response.data?.data?.accessToken };
  // });
  return response.data?.data?.accessToken;
};


axios.interceptors.request.use(
  async (config: any) => {
    if (!config.headers['Authorization']) {
      const session = await getSession();
      // const session = undefined
      config.headers['Authorization'] = `Bearer ${session?.accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Function to add authorization header if session is available
// const addAuthHeader = (config: any) => {
//   const session = useSession();
//   if (session.data && session.data.accessToken) {
//     config.headers['Authorization'] = `Bearer ${session.data.accessToken}`;
//   }
//   return config;
// };
// axios.interceptors.request.use(addAuthHeader);

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await refresh();
      if (!newAccessToken) {
        // await signOut();
        await signOut({ callbackUrl: '/auth/login', redirect: true });
        // await signOut({ redirectTo: '/auth/login', redirect: true });
        // return {};
      }
      const newConfig = {
        ...prevRequest,
        headers: {
          ...prevRequest.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      // prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
      // prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
      return axios(newConfig);
    }
    return Promise.reject(error);
  },
);

export default axios;
