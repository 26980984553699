import Image from '@ui/atoms/image';
import Svg from '@ui/atoms/svg/Svg';
import React from 'react'

function HeaderIcon(props: any) {
  const { styles, src, iconString } = props;
  return (
    <div className={`css-scope ${styles.header_btn}`}>
      <div id="header_menuIcon" className={`css-scope icon`}>
        {/* <Svg />e */}
        <Svg svg={iconString} />
        {/* <Image src={src} alt="menu" /> */}
        {/* <Icon/> */}
      </div>
    </div>
  )
}

export default HeaderIcon