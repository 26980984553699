
import styles from './Button.module.css';
function Button(props: any) {
  const  { onClick, children } = props;
  return (
    <button
      onClick={onClick}
      // type="button"
      className={`${styles.button} btn-primary`}
    >
      {children}
    </button>
  )
}

export default Button