'use client';
import React, { Fragment, useState, useEffect, useRef } from 'react';

// import './leftnav.css'
import styles from '@ui/molecules/LeftNav/LeftNav.module.css';
import headerStyles from '@ui/molecules/Header/Header.module.css';

import { ReactComponent as HomeIcon } from '../../icons/home.svg';
// import * as AiIcons from "react-icons/ai"
// import { IconContext } from "react-icons"
import LeftnavItem from './LeftnavItem';
import axios from "@lib/utils/axios/axios";
import { useRouter } from 'next/navigation';
import useApp from '@lib/hooks/useApp';
import dynamic from 'next/dynamic';
import Skeleton from 'react-loading-skeleton';
import HeaderIcon from '@ui/atoms/icons/header/HeaderIcon';
import { menuIconString } from '@ui/assets/icons/menu';

const HeaderMenuLogo = dynamic(() => import('@ui/molecules/header-menu-logo/HeaderMenuLogo'), {
  ssr: false,
  loading: () => <Skeleton height={'40px'} />
});

const HeaderLogo = dynamic(() => import('@ui/atoms/logos/HeaderLogo'), {
  ssr: false,
  loading: () => <Skeleton height={'40px'} />
});


const closeLeftNav = () => {
  document.getElementById('leftNavOutside')?.classList?.remove('visible');
  document.getElementById('leftNav')?.classList?.remove('active');
  removeScrollLock();
};
const toggleLeftNav = () => {
  document.getElementById('leftNavOutside')?.classList?.toggle('visible');
  document.getElementById('leftNav')?.classList?.toggle('active');
  //leftNav.current?.classList?.toggle('active')
  toggleScrollLock();
};
const toggleScrollLock = () => {
  document.querySelector('html')?.classList?.toggle('scroll-lock');
};
const removeScrollLock = () => {
  document.querySelector('html')?.classList?.remove('scroll-lock');
};
const onClickOutside = (event: any, ref: any) => {
  if (ref && ref.current.contains(event.target)) return;
  closeLeftNav();
};

export { toggleLeftNav, toggleScrollLock, onClickOutside };
function LeftNav(props: any) {
  const { leftMenuItems, lang } = props
  const router = useRouter();
  //const [leftMenuItems, setLeftMenuItems] = useState(initialLeftMenuItems);
  // const leftNav = useRef(null);
  // const outSideNav = useRef(null);
  // const history = useHistory();
  //const [{ leftNavIsActive, user, searchValue }, dispatch] = useApp(AppContext);
  // async function insertUserIntoDb(attributes: any) {
  // 	let response = await axios({
  // 		method: 'post',
  // 		url: `get_leftnav_menu`,
  // 	}).then((response) => {
  // 	})
  // 		.catch((error) => {
  // 		})
  // 		.finally(() => {
  // 		});
  // }

  // useEffect(() => {
  // 	// insertUserIntoDb();
  // }, [])

  // history.listen((location, action) => {
  // 	closeLeftNav();
  // })

  const { isLeftNavOpen, setIsLeftNavOpen } = useApp();

  // https://stackoverflow.com/questions/65884505/how-to-listen-to-page-changes-in-nextjs
  // router.events.on("routeChangeStart", closeLeftNav())
  // const onClickOutsideNav = (event, ref) => {
  // 	if (ref && ref.current.contains(event.target)) return;
  // 	closeLeftNav();
  // };

  // New added
  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
    }, [matches, query]);

    return matches;
  };

  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(1);
  const [animate, setAnimate] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const changeSmall = useMediaQuery('(max-height: 550px)');
  let delay = 1;
  useEffect(() => {
    setAnimate(true);
    let timer = setTimeout(() => setAnimate(false), delay * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [active, delay]);
  // New added

  return (
    <>
      {/* <IconContext.Provider value={{ color: '#050505' }} > */}
      <div
        id="leftNavOutside"
        onClick={() => setIsLeftNavOpen(false)}
        className={`css-scope tent ${isLeftNavOpen ? 'visible' : ''}`}
      ></div>
      {/* <div id="leftNavOutside" onClick={onClickOutsideNav} ref={outSideNav} className={`css-scope tent visible`} ></div> */}
      <div
        id="leftNav"
        className={`css-scope ${styles.leftnav} ${isLeftNavOpen ? 'active' : ''
          } ${expanded && 'expanded'}`}
        role="navigation"
        itemScope="itemscope"
        itemType="https://schema.org/SiteNavigationElement"
      >
        <div style={{
          display: 'flex',
          height: 'var(--theme-header-height)',
          alignItems: 'center',
          paddingLeft: '16px',
          flexDirection: 'row',
        }}>
          <>
            <div
              id="menu"
              className={`css-scope ${headerStyles.header_icon} ${headerStyles.menu_header_icon} waves-effect`}
              onClick={() => setIsLeftNavOpen(!isLeftNavOpen)}
            >
              <HeaderIcon
                styles={styles}
                iconString={menuIconString}
              />
            </div>
            <div onClick={() => router.push('/')}>
              <div style={{ minHeight: '40px', minWidth: '150px' }} className="bd-logo">
                <HeaderLogo lang={lang} />
              </div>
            </div>
          </>
        </div>
        <div className={`css-scope scrollbar-hidden ${styles.leftnav_scrollable}`}>
          <ul className={`css-scope ${styles.leftnav_scroll}`}>
            <li className={`css-scope list`}>
              <ul className={`css-scope ${styles.list_ul}`}>
                {leftMenuItems.length > 0 &&
                  leftMenuItems.map((item: any, index: any) => {
                    return <LeftnavItem lang={lang} key={`${index}-${item.id}`} item={item} />;
                  })}
              </ul>
            </li>
          </ul>
        </div>
      </div >
      {/* </IconContext.Provider> */}
    </>
  );
}

export default LeftNav;
