import type { Locale } from "@src/i18n-config";

const dictionaries = {
  en: () =>
    import("@src/dictionaries/en.json").then((module) => module.default),
  hi: () =>
    import("@src/dictionaries/hi.json").then((module) => module.default),
};

export const getClientDictionary = async (locale: Locale) =>
  await dictionaries[locale]();
