'use client';
import { useEffect, useState } from 'react';
// import './Header.css'
import styles from './Header.module.css';
// import 'common/css/search.css';
// import maleAvatar from 'common/images/avatar-male-30x30.jpeg'
import dynamic from 'next/dynamic';

import './NewDropDown.css';

//import { Helmet } from 'react-helmet'

// import { ReactComponent as BellIcon } from '../../icons/bell.svg';
// import { ReactComponent as MessengerIcon } from '../../icons/messenger.svg';
// import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
// import { ReactComponent as PlusIcon } from '../../icons/plus.svg';

// import Navbar, { setOpen } from './Navbar'

// import SelectSearch from 'react-select-search'
// import "react-virtualized-select/styles.css";
// import Select from "react-virtualized-select";
// import Select from 'react-select';
// import AsyncSelect from 'react-select/lib/Async';
// import "react-select/dist/react-select.css";

import { getClientDictionary } from '@lib/client-dictionary';
import useApp from '@lib/hooks/useApp';
import useSearch from '@lib/hooks/useSearch';
import useWindowSize from '@lib/hooks/useWindowSize';
import axios from "@lib/utils/axios/axios";
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/atoms/button/Button';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import Skeleton from 'react-loading-skeleton';
// import reducer, { initialState, getBasketTotal } from '../../reducer';

// const RightDropDown = dynamic(() => import('./RightDropDown'), {
//   ssr: false,
//   loading: () => <Skeleton height={'40px'} width={'100px'} />
// });

const HeaderMenuLogo = dynamic(
  () => import('../header-menu-logo/HeaderMenuLogo'), {
  ssr: false,
  loading: () => null
});

const Modal = dynamic(
  () => import('@ui/molecules/Modal/Modal'), {
  ssr: false,
  loading: () => null
});

const SearchModal = dynamic(
  () => import('@ui/molecules/SearchModal/SearchModal'), {
  ssr: false,
  loading: () => null
});

const Register = dynamic(
  () => import('@ui/molecules/Register/Register'), {
  ssr: false,
  loading: () => null
});

const Login = dynamic(
  () => import('@ui/molecules/Login/Login'), {
  ssr: false,
  loading: () => null
});

const CustomDesktopSearch = dynamic(
  () => import('@ui/molecules/CustomDesktopSearch/CustomDesktopSearch'), {
  ssr: false,
  loading: () => <Skeleton height={'35px'} />
});

const NewDropDown = dynamic(() => import('@ui/molecules/Header/NewDropDown'), {
  ssr: false,
  loading: () =>
    <div style={{ display: 'flex' }}>
      {[...Array(4)].map((_, index) => (
        <Skeleton key={index} height={'36px'} width={'36px'} circle={true} style={{ marginRight: '0.5rem' }} />
      ))}
    </div>
});

const ThemeDropdown = dynamic(() => import('./ThemeDropdown'), {
  ssr: false,
  loading: () => <Skeleton height={'36px'} width={'36px'} circle={true} style={{ marginRight: '0.5rem' }} />
});


const initialFormState = {
  group: '',
  login: '',
  q: '',
  searhedResult: '',
};
const initialSearchState: any[] | (() => any[]) = [];

const initialParams = {
  q: '',
  start: 0,
};

// const MyDynamicComponent = dynamic(() => import('./myComponent'), { ssr: false });
{
  /* <MyDynamicComponent /> */
}
// const MyDynamicComponent = dynamic(() => import('./myComponent'))
// {
// 	typeof window !== 'undefined' && (
// 		<MyDynamicComponent />
// 	)
// }

function Header({ lang, socialLoginProvider }: any) {

  const { data: dict, isFetching } = useQuery({
    queryKey: [
      'lang',
    ],
    queryFn: async () => getClientDictionary(lang)
  });

  const router = useRouter();
  const { q, setQ, getSearchResult } = useSearch();
  const session = useSession();
  // const header = useRef(null);

  // const ModalContent = '';
  // const appSession = '';
  // const stateValue = useApp(AppContext);

  // const [{ appSession, ModalContent, modalClassName }, dispatch] = useApp(AppContext);
  // const { auth } = useAuth();
  // const { setModalClass } = useApp()
  // const [{ appSession, ModalContent, modalClassName }, dispatch] = useApp(AppContext);
  // const [{ user, appSession, searchValue, ModalContent, modalClassName }, dispatch] = useApp(AppContext);
  const [params, setParams] = useState(initialParams);
  const [blogsSearch, setBlogsSearch] = useState([]);
  const [error, setError] = useState<any>(false);
  // const urlParams = new URLSearchParams((typeof window !== 'undefined') && window.location.search)
  const urlParams = new URLSearchParams();

  //const [q, setQ] = useState('')

  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [isNotMobile, setIsNotMobile] = useState<boolean | undefined>(
    undefined
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [formState, updateFormState] = useState(initialFormState);
  const [searchState, updateSearchState] = useState(initialSearchState);
  const [mobileSearchBar, setMobileSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);

  let emptyMessage = '';
  const size = useWindowSize()
  useEffect(() => {
    const isMobile = size && size.width <= 500;
    const isNotMobile = size && size.width > 500;
    setIsMobile(isMobile);
    setIsNotMobile(isNotMobile);
    return () => { };
  }, [size]);

  async function getSearchSuggestions(attributes?: any) {
    setLoading(true);
    setError(false);
    let response = await axios({
      method: 'post',
      // url: `blogs?search=${encodeURIComponent(q).replace(/%20/g, "+")}`,
      url: `search?q=${formState.q}`,
      // cancelToken: source.token
      // params: params
      // data: {
      // 	"user": {
      // 		"cognitoId": attributes.sub,
      // 		"firstName": attributes.name,
      // 		"lastName": "lastName9",
      // 		"email": attributes.email,
      // 		"phone": attributes.phone_number
      // 	}
      // }
    })
      .then((response) => {
        // setBlogsSearch(() => {
        // 	return [...new Set([...response?.data?.data])]
        // })
        // setTotalBlogs(response.data.total_blogs)
        // useEffect(() => {
        //     setBlogs([])
        // }, [query])
      })
      .catch((error) => {
        // if (axios.isCancel(error)) {
        // 	return
        // }
        setError(true);
      })
      .finally(() => {
        // setLoading(false)
      });
    // return () => source.cancel()
  }

  useEffect(() => {
    let text = urlParams.get('q');
    setQ(text);
    // return () => source.cancel()
  }, [urlParams.get('q')]);

  useEffect(() => {
    //window.scrollTo(0, 0)
    //setBlogs([])
    // if (q || params || formState.q) {
    if (formState.q) {
      getSearchSuggestions();
    }
    // return () => source.cancel()
  }, [q, params, formState.q]);


  function onChange(e) {
    e.persist();
    if (e.target.name === 'q' || e.target.name === 'q') {
      updateFormState(() => ({
        ...formState,
        q: e.target.value,
      }));
    } else {
      updateFormState(() => ({
        ...formState,
        login: { ...formState.login, [e.target.name]: e.target.value },
      }));
    }
  }

  function handleOnClickRegister(e) {
    e.persist();
    setRegisterModalOpen(true);
    // changeModalContent(<Register setModalOpen={setModalOpen} handleOnClickLogin={handleOnClickLogin} />, 'register_modal');
    // setModalOpen(true)
  }

  const { setModalClassName } = useApp();
  function handleOnClickLogin(e) {
    setLoginModalOpen(true);
    e.persist();
    // changeModalContent(<Login onSubmit={onSubmit} onChange={onChange} handleOnClickRegister={handleOnClickRegister} />);
    // changeModalContent(<Login setModalOpen={setModalOpen} handleOnClickRegister={handleOnClickRegister} />, 'login_modal');
    // setModalOpen(true)
  }

  function handleKeyDown(e) {
    //e.preventDefault();
    // updateFormState(() => ({
    // 	...formState,
    // 	q: e.target.value
    // }));
    let keyPressed = e.keyCode;
    if (keyPressed === 13) {
      e.preventDefault();
      closeMobileSearchBar();
      if (e.target.name === 'q') {
        router.push('/search?q=' + formState.q);
        // updateFormState(() => ({
        // 	...formState,
        // 	q: e.target.value
        // }));
      }
    }
  }

  const handleSearch = (e) => {
    // debugger
    // let row = searchState[e];
    updateFormState(() => ({
      ...formState,
      searchedResult: JSON.stringify(searchState),
    }));
    // var url = row.name.replaceAll(/[^\w\s]/gi, '').trim();
    // var url = url.replace(/ +(?= )/g, '').trim();
    // history.replace('/blog/'+encodeURIComponent(row.name).replace(/%20/g, "+").replace(`%3F`, ""))
    // history.push('/search?q=' + encodeURIComponent(row.name).replace(/%20/g, "+"))
  };
  function showMobileSearchBar() {
    setMobileSearchBar(!mobileSearchBar);
    setSearchModalOpen(true);
  }

  function closeMobileSearchBar() {
    setMobileSearchBar(false);
    setSearchModalOpen(false);
  }
  function onClickMobileSearchInput(e) {
    e.persist();
    setSearchModalOpen(true);
  }
  function onClickMobileSearchIcon(e) {
    e.persist();
    closeMobileSearchBar();
    router.push('/search?q=' + formState.q);
  }
  function SearchItem() {
    return (
      <ul className="list-group search_ul">
        {blogsSearch &&
          blogsSearch.map((item) => (
            <li key={`${index}-${item.id}`} className="list-group-item">
              {item.title}
            </li>
          ))}
        <button>close</button>
      </ul>
    );
  }

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos =
  //       typeof window !== 'undefined' && window.pageYOffset;
  //     if (prevScrollPos > currentScrollPos) {
  //       setVisible(true);
  //     } else {
  //       setVisible(false);
  //     }
  //     //setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
  //     setPrevScrollPos(currentScrollPos);
  //   };
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  //   }
  // }, [prevScrollPos, visible]);
  const showDropdown = () => { };
  // const filterColors = (inputValue) => {
  // 	debugger
  // 	return colourOptions.filter(i =>
  // 		i.label.toLowerCase().includes(inputValue.toLowerCase())
  // 	);
  // };

  // const promiseOptions = inputValue =>
  // 	new Promise(resolve => {
  // 		debugger
  // 		setTimeout(() => {
  // 			resolve(filterColors(inputValue));
  // 		}, 1000);
  // 	});

  // var prevScrollpos = window.pageYOffset;
  // window.onscroll = function () {
  // 	var currentScrollPos = window.pageYOffset;
  // 	if (prevScrollpos > currentScrollPos) {
  // 		document.getElementById("navbar").style.top = "0";
  // 	} else {
  // 		document.getElementById("navbar").style.top = "-50px";
  // 	}
  // 	prevScrollpos = currentScrollPos;
  // }

  return (
    // <section id="section_header" className="css-scope section_header" >
    <section id="section_header" className={`css-scope section_header`}>
      {loginModalOpen && (
        <Modal
          modalContainerClassName=""
          modalClassName="login_modal"
          setModalOpen={setLoginModalOpen}
        >
          <Login
            socialLoginProvider={socialLoginProvider}
            modalContainerClassName=""
            setModalOpen={setLoginModalOpen}
            handleOnClickRegister={handleOnClickRegister}
            lang={lang}
          />
          {/* <ModalContent /> */}
        </Modal>
      )}
      {registerModalOpen && (
        <Modal
          modalClassName="register_modal"
          setModalOpen={setRegisterModalOpen}
        >
          <Register
            // lang={lang}
            setModalOpen={setRegisterModalOpen}
            handleOnClickLogin={handleOnClickLogin}
          />
          {/* <ModalContent /> */}
        </Modal>
      )}
      {searchModalOpen && (
        <SearchModal
          setModalOpen={setSearchModalOpen}
          modalContainer_className="search_modal"
          modalClassName="search_modal_content"
          closeMobileSearchBar={closeMobileSearchBar}
        >
          <SearchItem />
        </SearchModal>
      )}
      <nav
        id="header"
        className={
          visible
            ? `css-scope ${styles.header}`
            : `css-scope ${styles.header} top`
        }
        itemScope="itemscope"
        itemType="https://schema.org/WPHeader"
      >
        <div className={styles.header_containerOne}>
          <div className={styles.header_containerTwo}>
            <div className={styles.header_containerThree}>
              <div className={`css-scope ${styles.header_itemStart}`}>
                <HeaderMenuLogo />
              </div>
              <div
                id="jk_top_nav_right_end"
                className={`css-scope ${styles.header_itemCenter}`}
              >
                {isNotMobile && <CustomDesktopSearch lang={lang} />}
                {/* {
                  isMobile && <div>is Mobile not logged in</div>
                }
                {
                  isMobile && <div>is Mobile logged in</div>
                } */}
                {/* {
                  isNotMobile && <Desktop
                    handleOnClickLogin={handleOnClickLogin}
                    handleOnClickRegister={handleOnClickRegister}
                  />
                } */}
              </div>
              <div className={`css-scope ${styles.header_itemEnd}`}>
                {
                  session?.data ?
                    <NewDropDown lang={lang} /> :
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ThemeDropdown />
                        {/* <div>
                          <button
                            onClick={handleOnClickRegister}
                            type="button"
                            className="btn btn-outline-primary mr-1"
                          >
                            {dict?.header?.joinNow}
                          </button>
                        </div> */}
                        <div>
                          {/* <button type="button" onClick={signOut} className="btn btn-primary">Sign Out</button>  */}
                          <Button
                            onClick={handleOnClickLogin}
                            type="button"
                            className="btn btn-primary"
                          >
                            {dict?.header?.login}
                          </Button>
                        </div>
                      </div>
                    </>
                }
              </div>
              <div
                style={{ display: 'none' }}
                id="jk_top_nav_right_end"
                className={`css-scope ${styles.header_itemCenter}`}
              >
                {/* <RightEnd
                  isMobile={isMobile}
                  isNotMobile={isNotMobile}
                  showMobileSearchBar={showMobileSearchBar}
                  styles={styles}
                  mobileSearchBar={mobileSearchBar}
                  mobileSearch={mobileSearch}
                  onClickMobileSearchInput={onClickMobileSearchInput}
                  onClickMobileSearchIcon={onClickMobileSearchIcon}
                  onChange={onChange}
                  handleKeyDown={handleKeyDown}
                  formState={formState}
                /> */}
                {/* {isMobile && (
                  <Mobile styles={styles} />
                )}
                {isNotMobile && (
                  <IsNotMobile styles={styles} />
                )} */}
              </div>
              {/* <RightDropDown
                isMobile={isMobile}
                styles={styles}
                handleOnClickRegister={handleOnClickRegister}
                handleOnClickLogin={handleOnClickLogin}
                isNotMobile={isNotMobile}
              /> */}
              <>
                {/* {typeof window !== 'undefined' && isMobile && (
        <div className={`css-scope ${styles.header_itemEnd}`}>
          <Navbar>
            <NavItem icon={<CaretIcon />}>
              <DropdownMenu
                handleOnClickRegister={handleOnClickRegister}
                handleOnClickLogin={handleOnClickLogin}
              ></DropdownMenu>
            </NavItem>
          </Navbar>
        </div>
      )} */}
                {/* {
                  isNotMobile && <div>Is not Mobile logged in</div>
                } */}

              </>
            </div>
          </div>
        </div>
      </nav>
      {/* <div className={styles.search_form_wrapper}>
        <Form className="search-form" id="" action="">
          <div className="jk-search-input-group">
            <Input
              type="text"
              name="search"
              className={`${styles.search} jk-form-control form-control`}
              placeholder="Search Blogs"
            />
            <div className="css-scope header_icon waves-effect">
              <div className="css-scope header_btn">
                <div className="css-scope icon">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon_svg"
                    viewBox="0 0 24 24"
                  >
                    <title>search</title>
                    <path d="M9.516 14.016q1.875 0 3.188-1.313t1.313-3.188-1.313-3.188-3.188-1.313-3.188 1.313-1.313 3.188 1.313 3.188 3.188 1.313zM15.516 14.016l4.969 4.969-1.5 1.5-4.969-4.969v-0.797l-0.281-0.281q-1.781 1.547-4.219 1.547-2.719 0-4.617-1.875t-1.898-4.594 1.898-4.617 4.617-1.898 4.594 1.898 1.875 4.617q0 0.984-0.469 2.227t-1.078 1.992l0.281 0.281h0.797z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="jk-searchbar-container"></div>
      </div> */}
    </section>
  );
}

export default Header;
