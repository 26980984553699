'use client';
import { createContext } from 'react';

// type AppContextType = {
//   modalClassName: string;
//   setModalClassName: React.Dispatch<React.SetStateAction<string>>;
// };

export const AppContext = createContext<any | undefined>(
  // export const AppContext = createContext<AppContextType | undefined>(
  undefined
);
