import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as BlogsIcon } from '../../icons/bell.svg';
import { ReactComponent as TutorialsIcon } from '../../icons/messenger.svg';
import { ReactComponent as TemplatesIcon } from '../../icons/caret.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import Image from 'next/image';

// import * as FaIcons from "react-icons/fa"
// import * as AiIcons from "react-icons/ai"
// import * as IoIcons from "react-icons/io"
import styles from '@ui/molecules/LeftNav/LeftNav.module.css';

import Link from "@ui/atoms/link/Link";
import PropTypes from 'prop-types';
import { usePathname, useRouter } from 'next/navigation';
import ActiveLink from '@ui/atoms/link/ActiveLink';

// export { NavLink };

// NavLink.propTypes = {
//     href: PropTypes.string.isRequired,
//     exact: PropTypes.bool
// };

// NavLink.defaultProps = {
//     exact: false
// };

// function NavLink({ href, exact, children, ...props }) {
//     const { pathname } = useRouter();
//     const isActive = exact ? pathname === href : pathname.startsWith(href);

//     if (isActive) {
//         item?.className += ' active';
//     }

//     return (
//         <Link lang={lang}href={href}>
//             <Link lang={lang}{...props}>
//                 {children}
//             </Link >
//         </Link>
//     );
// }

// function ActiveLink({ children, href }) {
//   const router = useRouter();
//   const style = {
//     color: router.asPath === href ? 'red' : 'black',
//   };

//   const handleClick = (e: any) => {
//     e.preventDefault();
//     router.push(href);
//   };

//   return (
//     <Link
//       href={href}
//       className={`a css-scope waves-effect ${styles.list_item_a}`}
//       onClick={handleClick}
//       style={style}
//     >
//       {children}
//     </Link>
//   );
// }

function LeftnavItem(props: any) {
  const { item, lang } = props;
  const pathname = usePathname()
  
  return (
    <li className={`css-scope list_item ${styles.list_item}`}>
      <ActiveLink lang={lang} href={item?.slug} style={{ display: 'flex !important' }}>
        <Image
          className={`icon ${pathname === `${item?.slug}` ? 'active' : ''}`}
          src={`/icons/sidebar/${item?.icon}.svg`}
          alt={item?.title ? item.title : 'Left Nav Icon'}
          width={28}
          height={28}
          style={{ width: '22px', height: '22px', margin: 'auto 1.5rem' }}
        />
        <div className={styles.main_text}>{item?.title}</div>
      </ActiveLink>
    </li>
  );
}
export default LeftnavItem;
