'use client'
import { getClientDictionary } from '@lib/client-dictionary';
import { useQuery } from '@tanstack/react-query';
import Form from '@ui/atoms/form/Form';
import '@ui/molecules/Login/Login.css';

function Library({ lang }: any) {

  const { data: dict, isFetching } = useQuery({
    queryKey: [
      'lang',
    ],
    queryFn: async () => getClientDictionary(lang)
  });

  function handleOnSubmit(e: any) {
    e.preventDefault();
  }

  return (
    <>
      <div className="notification_modal">
        <div id="login_content_container" className="login_secondDiv">
          <h5 className="text-center loginSignInText">Library</h5>
          <Form
            onSubmit={handleOnSubmit}
            id="login_form"
            className="form-auth login_form"
          >
            <button
              type="submit"
              id="login_btn"
              name="submit"
              className="btn btn-login login_btn btn-lg btn-primary btn-block text-uppercase"
            >
              {dict?.login?.SIGNIN}
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Library;
