// let axiosConfig = {
//   baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
//   headers: {
//     "x-api-key": process.env.NEXT_PUBLIC_API_KEY,
//     "server-key": "1312a113c58715637a94446389326a49",
//     "api-version": "1.0",
//     "bd-app-locale": "en",
//     "bd-app-name": "main",
// 'x-xsrf-token':  someCsrfToken,
//     "bd-auth-v1": "23232222;widjksu8shsdkfshnnfsjkiowowo",
//     // 'token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJVU0VSMTIzNDU2In0.tPD9rT5GWhsTKpUxTXC0NG_0obiTxvrR5W-TbsvvNoU',
//     // 'Content-Type': 'application/x-www-form-urlencoded',
//     // 'Content-Type': 'application/html',
//     // 'Access-Control-Allow-Origin':'*',
//     // 'Authorization': 'Basic YWRtaW46MTIzNA==',
//     // 'Access-Control-Allow-Methods':'GET, PUT, POST, DELETE, HEAD, OPTIONS',
//     // 'Accept': '*/*',
//     // 'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
//     // 'HOST':'localhost'
//     // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
//     "Content-Type": "application/json",
//     authorization: "",
//   },
//   // mode:'no-cors',
//   withCredentials: true,
//   // credentials: 'same-origin',
//   crossdomain: true
// };

export const axiosConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    // "x-api-key": process.env.NEXT_PUBLIC_API_KEY,
    // "server-key": "1312a113c58715637a94446389326a49",
    // "api-version": "1.0",
    // "bd-app-locale": "en",
    // "bd-app-name": "main",
    // "bd-auth-v1": "23232222;widjksu8shsdkfshnnfsjkiowowo",
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    // authorization: "",
  },
  // mode:'no-cors',
  // withCredentials: true,
  // credentials: 'same-origin',
  // crossdomain: true,
};
