import styles from './Form.module.css'

function Form({ children, ...props }: any) {
  return (
    <form
      {...props}
      className={styles.form}
    >
      {children}
    </form>
  )
}

export default Form