'use client'
import React, { useEffect, useState } from 'react';
// import './footer.css';
import bdDarkLogo from './bdDarkLogo.svg';
import referAndEarnLogo from './referAndEarnLogo.svg';
import settingsLogo from './settingsLogo.svg';
import Svg from '@ui/atoms/svg/Svg';
import Link from "@ui/atoms/link/Link";
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import useWindowSize from '@lib/hooks/useWindowSize';
import ActiveLink from '@ui/atoms/link/ActiveLink';
import Modal from '@ui/molecules/Modal/Modal';
import Notifications from '@ui/molecules/notifications/Notifications';
import Library from '@ui/molecules/library/Library';
import './Footer.css';

function Footer(props: any) {
  const { lang, footerMenuItems } = props
  const router = useRouter();
  const pathname = usePathname();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const currentScrollPos =
      typeof window !== 'undefined' && window.pageYOffset;
    if (prevScrollPos > currentScrollPos) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    //setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
    // setPrevScrollPos(currentScrollPos);
  };
  // useEffect(() => {
  // 	(typeof window !== 'undefined') && window.addEventListener('scroll', handleScroll);
  // 	return () => (typeof window !== 'undefined') && window.removeEventListener('scroll', handleScroll);
  // }, [prevScrollPos, visible, handleScroll]);

  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [isNotMobile, setIsNotMobile] = useState<boolean | undefined>(
    undefined
  );
  const size = useWindowSize()
  useEffect(() => {
    const isMobile = size && size.width <= 500;
    const isNotMobile = size && size.width > 500;
    setIsMobile(isMobile);
    setIsNotMobile(isNotMobile);
    return () => { };
  }, [size]);

  const handleFooterMenuOnclick = (funcName: string) => {
    if (funcName) {
      eval(funcName + "(true)");
    }
    return
  }

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [libraryModalOpen, setLibraryModalOpen] = useState(false);
  return (
    <section id="section_footer" className="css-scope section_footer">
      {notificationModalOpen && (
        <Modal setModalOpen={setNotificationModalOpen}>
          <Notifications
            setModalOpen={setNotificationModalOpen}
            lang={lang}
          />
          {/* <ModalContent /> */}
        </Modal>
      )}
      {libraryModalOpen && (
        <Modal setModalOpen={setLibraryModalOpen}>
          <Library
            setModalOpen={setLibraryModalOpen}
            lang={lang}
          />
          {/* <ModalContent /> */}
        </Modal>
      )}
      {isMobile && (
        <nav className="footer_nav show-on-small_tablet hide show">
          <div className="footer_container1">
            <div className="footer_container2">
              <div className="footer_container3">
                <div
                  className="css-scope footer_item footer_item-center"
                  role="tablist"
                >
                  {
                    footerMenuItems?.map((item: any) => (
                      <div
                        key={item?.key}
                        onClick={() => item?.funcName ? handleFooterMenuOnclick(item?.funcName) : router.push(item?.slug)}
                        className="css-scope footerIcon_container waves-effect"
                        role="tab"
                      >
                        <div className="css-scope footer_icon">
                          <div className="css-scope footer_btn">
                            <Image
                              className={`icon ${pathname === item?.slug ? 'active' : ''}`}
                              src={`/icons/sidebar/${item?.icon}.svg`}
                              alt={`${item?.title} icon`}
                              width={28}
                              height={28}
                            />
                          </div>
                        </div>
                        <div className={`icon-name ${pathname === item?.slug ? 'active' : ''}`}>
                          {item?.title}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </section>
  );
}

export default Footer;
