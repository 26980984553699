import LibLink from 'next/link'
import { i18n } from '@src/i18n-config'
interface LinkProps {
  href: string
  children: React.ReactNode
  [key: string]: any
}
import styles from '@ui/molecules/LeftNav/LeftNav.module.css';
import { usePathname, useRouter } from 'next/navigation'

export default function ActiveLink({ lang, href, children, ...props }: LinkProps) {
  const pathname = usePathname()
  const style = {
    color: pathname === `${href}` ? 'red' : 'black',
  };

  const isDefaultLang = lang === i18n.defaultLocale
  const path = isDefaultLang ? href : (lang ? `/${lang}${href}` : (href || ''));
  return <LibLink style={style} className={`a css-scope waves-effect ${styles.list_item_a}`} href={path} {...props} >{children}</LibLink>
}